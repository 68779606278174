import React, { useState } from 'react'
import MobileMenu2 from '../MobileMenu2/MobileMenu2';
import Logo from '../../images/logo.svg'
import { totalPrice } from "../../utils";
import { connect } from "react-redux";
import { Link} from 'react-scroll'
import {NavLink} from 'react-router-dom'


const Header2 = (props) => {
    const [menuActive, setMenuState] = useState(false);
    const [cartActive, setcartState] = useState(false);

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const scrollToTop = () => {
        window.scrollTo(10, 0);
    }

    const { carts } = props;

    return (
        <header id="header" className={props.topbarNone}>
            <div className={`wpo-site-header wpo-header-style-2 ${props.hclass}`}>
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="">
                        <div className="mobail-menu">
                            <MobileMenu2 />
                        </div>
                    </div>
                    <div className="navbar-hide">
                        <div className="navbar-header">
                            <Link className="navbar-brand" to="/"><img src={Logo} alt="" /></Link>
                        </div>
                    </div>
                    <div className="">
                        <div id="navbar" className="collapse navbar-collapse navigation-holder">
                            <button className="menu-close"><i className="ti-close"></i></button>
                            <ul className="nav navbar-nav mb-2 mb-lg-0">
                                <li><NavLink onClick={scrollToTop} to="/">Home</NavLink></li>
                                <li><NavLink onClick={scrollToTop} to="/travel" >Travel</NavLink></li>
                                <li><NavLink onClick={scrollToTop} to="/schedule">Schedule</NavLink></li>
                                <li><NavLink onClick={scrollToTop} className="navbar-brand" to="/"><img src={Logo} alt="" /></NavLink></li>
                                <li><NavLink onClick={scrollToTop} to="/faq">FAQ</NavLink></li>
                                {/* <li><NavLink onClick={scrollToTop} to="/wedding-party">Party</NavLink></li> */}
                                <li><NavLink onClick={scrollToTop} to="/photos">Photos</NavLink></li>
                                <li><a href="https://registry.theknot.com/-june-2023-va/58317888" target="__blank">Registry</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header2;