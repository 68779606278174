import React, { Fragment, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll'
import { AuthContext } from '../../contexts/authContext';
import './style.css';

const MobileMenu2 = () => {
    const [menuActive, setMenuState] = useState(false);
    const authContext = useContext(AuthContext)

    const scrollToTop = () => {
        window.scrollTo(10, 0);
    }

    if (menuActive) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    } else {
      document.body.style = {}
    }

    return (
        <div>
            <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                <div className="menu-close">
                    <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                </div>
                <ul className="responsivemenu">
                    <li><NavLink onClick={scrollToTop} to="/">Home</NavLink></li>
                    <li><NavLink onClick={scrollToTop} to="/travel" >Travel</NavLink></li>
                    <li><NavLink onClick={scrollToTop} to="/faq">FAQ</NavLink></li>
                    <li><NavLink onClick={scrollToTop} to="/schedule">Schedule</NavLink></li>
                    <li><NavLink onClick={scrollToTop} to="/photos">Photos</NavLink></li>
                    <li><a href="https://registry.theknot.com/-june-2023-va/58317888" target="__blank">Registry</a></li>
                    <li><Link className="link-bottom" to="/login" onClick={authContext.signOut}>Sign out</Link></li>
                </ul>
            </div>
            <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
                <button type="button" className="navbar-toggler open-btn">
                    <span className="icon-bar first-angle"></span>
                    <span className="icon-bar middle-angle"></span>
                    <span className="icon-bar last-angle"></span>
                </button>
            </div>
        </div>
    )
}

export default MobileMenu2;