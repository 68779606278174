import { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import SectionTitle from '../../components/SectionTitle';
import OfferBanner from '../../components/OfferBanner/OfferBanner';
import ComingSoon from '../../components/ComingSoon';
import PageTitle from '../../components/pagetitle/PageTitle';
import Footer from '../../components/footer/Footer';



const Registry = () => {
    return (
        <Fragment>
            <Navbar2 />
            <PageTitle pageTitle={'Registry'}/> 
            <ComingSoon />
            <Footer />
        </Fragment>
    )
};

export default Registry;