import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.svg'
import { connect } from "react-redux"
import { AuthContext } from '../../contexts/authContext'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Footer = (props) => {
    const authContext = useContext(AuthContext)
    return (
        <div className="wpo-site-footer text-center">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-link">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/login" onClick={authContext.signOut}>Sign out</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
  