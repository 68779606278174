import { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import Hero3 from '../../components/hero3/hero3';
import WeddingDate from '../../components/WeddingDate/WeddingDate';
import StorySection from '../../components/StorySection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import FunFact from '../../components/FunFact/FunFact';
import NextPagePromo from '../../components/NextPagePromo';



const Home = () => {
    return (
        <Fragment>
            <Navbar2 />
            <Hero3 />
            <WeddingDate />
            <StorySection stClass={'wpo-story-section-s2 pb-0'}/>
            <FunFact />
            <NextPagePromo
              title="Hotel Recommendation"
              description='Looking for travel information, including hotel recommendations?'
              linkPath='/travel'
              linkText='View Travel Page'
            />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default Home;