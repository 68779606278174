import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Error from '../../components/404/404';
import Navbar2 from '../../components/Navbar2/Navbar2';



const ErrorPage =() => {
    return(
        <Fragment>
            <Navbar2 />
            <Error/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

export default ErrorPage;
