import React from 'react'
import { Link } from 'react-router-dom'
import sImg1 from '../../images/slider/shape.png'
import sImg2 from '../../images/slider/shape2.png'

const PageTitle = (props) => {
    return(
        <section className="wpo-page-title">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="wpo-breadcumb-wrap">
                            <span className="shape-1"><img src={sImg1} alt="" /></span>
                                <h2>{props.pageTitle}</h2>
                            <span className="shape-2"><img src={sImg2} alt="" /> </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageTitle;