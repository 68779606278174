import React from "react";
import { NavLink } from "react-router-dom";

const ComingSoon = () => {
    return (
        <section className="wpo-comingsoon-banner-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-lg-12 col-12">
                        <div className="offer-banner-text">
                            <div className="offer-banner-text-inner">
                                <span>Coming soon</span>
                                <p>This page is coming soon</p>
                                <NavLink className="theme-btn"  to="/" >Home </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ComingSoon;