"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePhotoUploadUpdateSubscription = exports.PhotoUploadUpdateDocument = exports.usePhotoProcessingUpdateSubscription = exports.PhotoProcessingUpdateDocument = exports.useListSimilarPhotosLazyQuery = exports.useListSimilarPhotosQuery = exports.ListSimilarPhotosDocument = exports.useListPublishedMasonryPhotosLazyQuery = exports.useListPublishedMasonryPhotosQuery = exports.ListPublishedMasonryPhotosDocument = exports.useListMasonryImagesLazyQuery = exports.useListMasonryImagesQuery = exports.ListMasonryImagesDocument = exports.useGetQuestionsLazyQuery = exports.useGetQuestionsQuery = exports.GetQuestionsDocument = exports.useUploadMasonryPhotoMutation = exports.UploadMasonryPhotoDocument = exports.useUpdateMasonryPhotoProcessingMutation = exports.UpdateMasonryPhotoProcessingDocument = exports.useUpdateMasonryPhotoDetailsMutation = exports.UpdateMasonryPhotoDetailsDocument = exports.useUnpublishMasonryPhotoMutation = exports.UnpublishMasonryPhotoDocument = exports.useReProcessMasonryPhotoMutation = exports.ReProcessMasonryPhotoDocument = exports.usePublishMasonryPhotoMutation = exports.PublishMasonryPhotoDocument = exports.useDeleteMasonryPhotoMutation = exports.DeleteMasonryPhotoDocument = exports.useAddContactQuestionMutation = exports.AddContactQuestionDocument = exports.ReProcessMasonryPhotoInputOperation = exports.MasonryPhotoStatus = void 0;
const client_1 = require("@apollo/client");
const Apollo = __importStar(require("@apollo/client"));
const defaultOptions = {};
var MasonryPhotoStatus;
(function (MasonryPhotoStatus) {
    MasonryPhotoStatus["Draft"] = "DRAFT";
    MasonryPhotoStatus["Processing"] = "PROCESSING";
    MasonryPhotoStatus["ProcessingError"] = "PROCESSING_ERROR";
    MasonryPhotoStatus["Published"] = "PUBLISHED";
})(MasonryPhotoStatus = exports.MasonryPhotoStatus || (exports.MasonryPhotoStatus = {}));
var ReProcessMasonryPhotoInputOperation;
(function (ReProcessMasonryPhotoInputOperation) {
    ReProcessMasonryPhotoInputOperation["RotateLeft"] = "ROTATE_LEFT";
    ReProcessMasonryPhotoInputOperation["RotateRight"] = "ROTATE_RIGHT";
    ReProcessMasonryPhotoInputOperation["UpdateMetadata"] = "UPDATE_METADATA";
})(ReProcessMasonryPhotoInputOperation = exports.ReProcessMasonryPhotoInputOperation || (exports.ReProcessMasonryPhotoInputOperation = {}));
exports.AddContactQuestionDocument = (0, client_1.gql) `
    mutation AddContactQuestion($input: AddContactQuestionInput!) {
  addContactQuestion(input: $input) {
    success
    info
  }
}
    `;
/**
 * __useAddContactQuestionMutation__
 *
 * To run a mutation, you first call `useAddContactQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactQuestionMutation, { data, loading, error }] = useAddContactQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useAddContactQuestionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.AddContactQuestionDocument, options);
}
exports.useAddContactQuestionMutation = useAddContactQuestionMutation;
exports.DeleteMasonryPhotoDocument = (0, client_1.gql) `
    mutation deleteMasonryPhoto($id: String!) {
  deleteMasonryPhoto(id: $id)
}
    `;
/**
 * __useDeleteMasonryPhotoMutation__
 *
 * To run a mutation, you first call `useDeleteMasonryPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMasonryPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMasonryPhotoMutation, { data, loading, error }] = useDeleteMasonryPhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function useDeleteMasonryPhotoMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.DeleteMasonryPhotoDocument, options);
}
exports.useDeleteMasonryPhotoMutation = useDeleteMasonryPhotoMutation;
exports.PublishMasonryPhotoDocument = (0, client_1.gql) `
    mutation publishMasonryPhoto($id: String!) {
  publishMasonryPhoto(id: $id) {
    id
    status
  }
}
    `;
/**
 * __usePublishMasonryPhotoMutation__
 *
 * To run a mutation, you first call `usePublishMasonryPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMasonryPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMasonryPhotoMutation, { data, loading, error }] = usePublishMasonryPhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function usePublishMasonryPhotoMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.PublishMasonryPhotoDocument, options);
}
exports.usePublishMasonryPhotoMutation = usePublishMasonryPhotoMutation;
exports.ReProcessMasonryPhotoDocument = (0, client_1.gql) `
    mutation reProcessMasonryPhoto($input: ReProcessMasonryPhotoInput!) {
  reProcessMasonryPhoto(input: $input)
}
    `;
/**
 * __useReProcessMasonryPhotoMutation__
 *
 * To run a mutation, you first call `useReProcessMasonryPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReProcessMasonryPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reProcessMasonryPhotoMutation, { data, loading, error }] = useReProcessMasonryPhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useReProcessMasonryPhotoMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.ReProcessMasonryPhotoDocument, options);
}
exports.useReProcessMasonryPhotoMutation = useReProcessMasonryPhotoMutation;
exports.UnpublishMasonryPhotoDocument = (0, client_1.gql) `
    mutation unpublishMasonryPhoto($id: String!) {
  unpublishMasonryPhoto(id: $id) {
    id
    status
  }
}
    `;
/**
 * __useUnpublishMasonryPhotoMutation__
 *
 * To run a mutation, you first call `useUnpublishMasonryPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishMasonryPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishMasonryPhotoMutation, { data, loading, error }] = useUnpublishMasonryPhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function useUnpublishMasonryPhotoMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.UnpublishMasonryPhotoDocument, options);
}
exports.useUnpublishMasonryPhotoMutation = useUnpublishMasonryPhotoMutation;
exports.UpdateMasonryPhotoDetailsDocument = (0, client_1.gql) `
    mutation updateMasonryPhotoDetails($input: UpdateMasonryPhotoDetails!) {
  updateMasonryPhotoDetails(input: $input) {
    id
    status
    title
    description
    order
  }
}
    `;
/**
 * __useUpdateMasonryPhotoDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateMasonryPhotoDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasonryPhotoDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasonryPhotoDetailsMutation, { data, loading, error }] = useUpdateMasonryPhotoDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpdateMasonryPhotoDetailsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.UpdateMasonryPhotoDetailsDocument, options);
}
exports.useUpdateMasonryPhotoDetailsMutation = useUpdateMasonryPhotoDetailsMutation;
exports.UpdateMasonryPhotoProcessingDocument = (0, client_1.gql) `
    mutation updateMasonryPhotoProcessing($input: UpdateMasonryPhotoProcessing!) {
  updateMasonryPhotoProcessing(input: $input) {
    id
    status
    dominantColor
    hash
    width
    height
  }
}
    `;
/**
 * __useUpdateMasonryPhotoProcessingMutation__
 *
 * To run a mutation, you first call `useUpdateMasonryPhotoProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasonryPhotoProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasonryPhotoProcessingMutation, { data, loading, error }] = useUpdateMasonryPhotoProcessingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpdateMasonryPhotoProcessingMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.UpdateMasonryPhotoProcessingDocument, options);
}
exports.useUpdateMasonryPhotoProcessingMutation = useUpdateMasonryPhotoProcessingMutation;
exports.UploadMasonryPhotoDocument = (0, client_1.gql) `
    mutation uploadMasonryPhoto($input: UploadMasonryPhotoInput!) {
  uploadMasonryPhoto(input: $input) {
    id
    author
    status
    height
    width
    hash
    order
    dominantColor
    uploadDate
    title
    description
    location
  }
}
    `;
/**
 * __useUploadMasonryPhotoMutation__
 *
 * To run a mutation, you first call `useUploadMasonryPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMasonryPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMasonryPhotoMutation, { data, loading, error }] = useUploadMasonryPhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useUploadMasonryPhotoMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(exports.UploadMasonryPhotoDocument, options);
}
exports.useUploadMasonryPhotoMutation = useUploadMasonryPhotoMutation;
exports.GetQuestionsDocument = (0, client_1.gql) `
    query getQuestions {
  getQuestions {
    name
    email
    question
    date
  }
}
    `;
/**
 * __useGetQuestionsQuery__
 *
 * To run a query within a React component, call `useGetQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
function useGetQuestionsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.GetQuestionsDocument, options);
}
exports.useGetQuestionsQuery = useGetQuestionsQuery;
function useGetQuestionsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.GetQuestionsDocument, options);
}
exports.useGetQuestionsLazyQuery = useGetQuestionsLazyQuery;
exports.ListMasonryImagesDocument = (0, client_1.gql) `
    query listMasonryImages {
  listMasonryImages {
    id
    author
    title
    description
    status
    order
    dominantColor
    height
    width
    uploadDate
    hash
    location
  }
}
    `;
/**
 * __useListMasonryImagesQuery__
 *
 * To run a query within a React component, call `useListMasonryImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMasonryImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMasonryImagesQuery({
 *   variables: {
 *   },
 * });
 */
function useListMasonryImagesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.ListMasonryImagesDocument, options);
}
exports.useListMasonryImagesQuery = useListMasonryImagesQuery;
function useListMasonryImagesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.ListMasonryImagesDocument, options);
}
exports.useListMasonryImagesLazyQuery = useListMasonryImagesLazyQuery;
exports.ListPublishedMasonryPhotosDocument = (0, client_1.gql) `
    query listPublishedMasonryPhotos($input: ListPublishedMasonryPhotosInput) {
  listPublishedMasonryPhotos(input: $input) {
    list {
      id
      title
      description
      order
      dominantColor
      width
      height
      hash
    }
    cursor
  }
}
    `;
/**
 * __useListPublishedMasonryPhotosQuery__
 *
 * To run a query within a React component, call `useListPublishedMasonryPhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPublishedMasonryPhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPublishedMasonryPhotosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useListPublishedMasonryPhotosQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.ListPublishedMasonryPhotosDocument, options);
}
exports.useListPublishedMasonryPhotosQuery = useListPublishedMasonryPhotosQuery;
function useListPublishedMasonryPhotosLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.ListPublishedMasonryPhotosDocument, options);
}
exports.useListPublishedMasonryPhotosLazyQuery = useListPublishedMasonryPhotosLazyQuery;
exports.ListSimilarPhotosDocument = (0, client_1.gql) `
    query listSimilarPhotos($id: String!) {
  listSimilarPhotos(id: $id) {
    items {
      id
      hash
      similarity
      status
    }
    cursor
  }
}
    `;
/**
 * __useListSimilarPhotosQuery__
 *
 * To run a query within a React component, call `useListSimilarPhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSimilarPhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSimilarPhotosQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function useListSimilarPhotosQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(exports.ListSimilarPhotosDocument, options);
}
exports.useListSimilarPhotosQuery = useListSimilarPhotosQuery;
function useListSimilarPhotosLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(exports.ListSimilarPhotosDocument, options);
}
exports.useListSimilarPhotosLazyQuery = useListSimilarPhotosLazyQuery;
exports.PhotoProcessingUpdateDocument = (0, client_1.gql) `
    subscription photoProcessingUpdate {
  onUpdateMasonryPhotoProcessing {
    id
    status
    height
    width
    hash
    dominantColor
  }
}
    `;
/**
 * __usePhotoProcessingUpdateSubscription__
 *
 * To run a query within a React component, call `usePhotoProcessingUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePhotoProcessingUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotoProcessingUpdateSubscription({
 *   variables: {
 *   },
 * });
 */
function usePhotoProcessingUpdateSubscription(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription(exports.PhotoProcessingUpdateDocument, options);
}
exports.usePhotoProcessingUpdateSubscription = usePhotoProcessingUpdateSubscription;
exports.PhotoUploadUpdateDocument = (0, client_1.gql) `
    subscription photoUploadUpdate {
  onUploadMasonryPhoto {
    id
    author
    status
    height
    width
    hash
    order
    dominantColor
    uploadDate
    title
    description
    location
  }
}
    `;
/**
 * __usePhotoUploadUpdateSubscription__
 *
 * To run a query within a React component, call `usePhotoUploadUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePhotoUploadUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotoUploadUpdateSubscription({
 *   variables: {
 *   },
 * });
 */
function usePhotoUploadUpdateSubscription(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription(exports.PhotoUploadUpdateDocument, options);
}
exports.usePhotoUploadUpdateSubscription = usePhotoUploadUpdateSubscription;
