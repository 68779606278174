import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from '../Home';
import LoginPage from '../LoginPage'
import ForgotPassword from '../ForgotPassword'
import ErrorPage from '../Error/ErrorPage';
import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from '../../contexts/authContext'
import Travel from '../Travel';
import Schedule from '../Schedule';
import FAQ from '../FAQ';
import WeddingParty from '../WeddingParty';
import Registry from '../Registry';
import Photos from '../Photos';
import RSVPPage from '../RSVPPage';

const AllRoute = (props) => {
  return (
    <div className="App">
      <AuthProvider>
        <AuthIsSignedIn>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Home />}/>
              <Route path='travel' element={<Travel />}/>
              <Route path='schedule' element={<Schedule />}/>
              <Route path='faq' element={<FAQ />}/>
              <Route path='wedding-party' element={<WeddingParty />}/>
              <Route path='photos' element={<Photos />}/>
              <Route path='registry' element={<Registry />}/>
              <Route path='rsvp' element={<RSVPPage />} />
              <Route path='login' element={<LoginPage />} />
              <Route path='forgot-password' element={<ForgotPassword />} />
              <Route path='/404' element={<ErrorPage/>} />
              <Route path='*' element={<ErrorPage/>} />
            </Routes>
          </BrowserRouter>
        </AuthIsSignedIn>
        <AuthIsNotSignedIn>
          <BrowserRouter>
            <Routes>
              <Route path='login' element={<LoginPage />} />
              <Route path='*' element={<Navigate to="/login" />}/>
            </Routes>
          </BrowserRouter>
        </AuthIsNotSignedIn>
      </AuthProvider>
    </div>
  );
}

export default AllRoute;
