import React from 'react'
import { Link } from 'react-router-dom'

interface NextPagePromoProps {
  title: string
  description: string
  linkPath: string
  linkText: string
}

const NextPagePromo = (props: NextPagePromoProps) => {
  const ClickHandler = () =>{
    window.scrollTo(10, 0);
    }

  return(
    <section className="next-page-promo">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="content clearfix">
              <div className="promo-message">
                <h3>{props.title}</h3>
                <p>{props.description}</p>
                <Link onClick={ClickHandler} to={props.linkPath} className="theme-btn">{props.linkText}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NextPagePromo;