import { Fragment, useEffect, useMemo, useState } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import Footer from '../../components/footer/Footer';
import PageTitle from '../../components/pagetitle/PageTitle';
import { ListPublishedMasonryPhotosQuery, useListPublishedMasonryPhotosLazyQuery } from '@wedding/api-schema/graphql-apollo';
import NextPagePromo from '../../components/NextPagePromo';
import { Masonry, useInfiniteLoader } from "masonic";
import { useDebounceCallback } from '@react-hook/debounce'

type MasonItemType = ListPublishedMasonryPhotosQuery["listPublishedMasonryPhotos"]["list"][0]
const MasonItem = ({ data, width: widthT }: {
  data: MasonItemType, width: number
}) => {
  const heightT = data.height && data.width ? Number.parseInt(data.height) / Number.parseInt(data.width) * widthT : 300;

  return (
    <div style={{ background: data.dominantColor || "#fff", height: heightT }} >
      <img src={`${window.location.hostname.includes("localhost") ? "https://beta.catherine-jose.wedding/" : ""}published/photos/${data.id}.medium.webp`}/>
    </div>
  )
}

const Photos = () => {
  const [loadImages, {
    data: imageQueryData
  }] = useListPublishedMasonryPhotosLazyQuery()
  const [images, setImages] = useState<MasonItemType[]>([])
  const [cursor, setCursor] = useState<string>()

  useEffect(() => {
    loadImages({
      variables: {
        input: {
          limit: 25,
          cursor
        }
      }
    })
  }, [])

  useEffect(() => {
    setImages([...images, ...(imageQueryData?.listPublishedMasonryPhotos.list || [])])
    setCursor(imageQueryData?.listPublishedMasonryPhotos.cursor || undefined)
  }, [imageQueryData])

  const maybeLoadMore = useInfiniteLoader(
    async () => {
      loadImages({
        variables: {
          input: {
            limit: 25,
            cursor,
          }
        }
      })
    }, {
      totalItems: cursor ? 9e9 : images.length
    });
  
  const debouncedCallback = useDebounceCallback(maybeLoadMore, 300)

  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={'Memory Wall'} pagesub={'Memory Wall'}/> 
      <div className="container">
        <div className="sortable-gallery">
          <div className="gallery-filters"></div>
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-grids gallery-container clearfix">
                <Masonry
                  items={images}
                  render={MasonItem}
                  maxColumnCount={3}
                  rowGutter={30}
                  columnGutter={30}
                  overscanBy={1.2}
                  onRender={debouncedCallback}
                  itemKey={item => item.id}
                  role="list"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <NextPagePromo
        title="Frequently Asked Questions"
        description='Looking for answers to common questions about our wedding? We’ve got you covered.'
        linkPath='/faq'
        linkText='View FAQ Page'
      />
      <Footer />
    </Fragment>
  )
};

export default Photos;