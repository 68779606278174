import React, { useState, useEffect, useContext } from 'react'

import * as cognito from '../libs/cognito'
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, RequestHandler, createHttpLink } from '@apollo/client';
import { setContext } from "@apollo/client/link/context"
import { getEnv } from '../utils/environment';
import { CognitoIdentityCredentialProvider, fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { RetryLink } from "@apollo/client/link/retry";

export enum AuthStatus {
  Loading = "Loading",
  SignedIn = "SignedIn",
  SignedOut = "SignedOut",
}

export interface IAuth {
  sessionInfo?: { username?: string; email?: string; sub?: string; accessToken?: string; refreshToken?: string }
  attrInfo?: any
  authStatus?: AuthStatus
  signInAsGuest?: any
  signInWithEmail?: any
  signUpWithEmail?: any
  signOut?: any
  verifyCode?: any
  getSession?: any
  sendCode?: any
  forgotPassword?: any
  changePassword?: any
  getAttributes?: any
  setAttribute?: any
  credentials?: CognitoIdentityCredentialProvider
  fetchToken: () => Promise<string>
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
  fetchToken: () => { throw new Error("not implemented") }
}

type Props = {
  children?: React.ReactNode
}

export const AuthContext = React.createContext(defaultState)

const setAuthLink = (fetchToken: () => Promise<string>) => 
  setContext(
    () => new Promise((success) => {
      fetchToken().then((token) => {
        success({
          headers: {
            authorization: token
          }
        })
      })
    })
  )

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus, fetchToken }: IAuth = useContext(AuthContext)
  const origin = window.location.origin.includes("localhost") ? "https://cyo35pmobrazhgrhv2bt4cpega.appsync-api.us-east-1.amazonaws.com" : window.location.origin
  const client = new ApolloClient({
    link: ApolloLink.from([
      new RetryLink({
        attempts: {
          max: 5
        },
      }),
      new ApolloLink((operation, forward) => {
        return forward(operation).map((data) => {
          if (data?.errors?.some((e: any) => e.errorType === "Lambda:IllegalArgument")) {
            throw new Error('GraphQL Operational Error');
          }
          return data;
        });
      }),
      setAuthLink(fetchToken),
      createHttpLink({
        uri: origin + "/graphql",
        // useGETForQueries: true,
      })
    ]),
    cache: new InMemoryCache(),
  });
  return <>{authStatus === AuthStatus.SignedIn ? <ApolloProvider client={client}>{children}</ApolloProvider> : null}</>
}

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  const [sessionInfo, setSessionInfo] = useState({})
  const [attrInfo, setAttrInfo] = useState([])
  const [credentials, setCredentials] = useState<CognitoIdentityCredentialProvider>()

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session: any = await getSession()
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
        })
        setCredentials(fromCognitoIdentityPool({
          identityPoolId: getEnv().identityPoolId,
          clientConfig: {
            region: "us-east-1",
          },
          logins: {
            [`cognito-idp.us-east-1.amazonaws.com/${getEnv().userPoolId}`]: session.getIdToken().getJwtToken()
          },
        }))
        // window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
        // window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
        // await setAttribute({ Name: 'website', Value: 'https://github.com/dbroadhurst/aws-cognito-react' })
        const attr: any = await getAttributes()
        setAttrInfo(attr)
        setAuthStatus(AuthStatus.SignedIn)
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut)
      }
    }
    getSessionInfo()
  }, [setAuthStatus, authStatus])

  if (authStatus === AuthStatus.Loading) {
    return null
  }

  async function signInAsGuest(password: string) {
    try {
      await cognito.signInAsGuest(password)
      setAuthStatus(AuthStatus.SignedIn)
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }

  async function signInWithEmail(username: string, password: string) {
    try {
      await cognito.signInWithEmail(username, password)
      setAuthStatus(AuthStatus.SignedIn)
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }
  
  async function signUpWithEmail(username: string, email: string, password: string) {
    try {
      await cognito.signUpUserWithEmail(username, email, password)
    } catch (err) {
      throw err
    }
  }

  function signOut() {
    cognito.signOut()
    setAuthStatus(AuthStatus.SignedOut)
  }

  async function verifyCode(username: string, code: string) {
    try {
      await cognito.verifyCode(username, code)
    } catch (err) {
      throw err
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession()
      return session
    } catch (err) {
      throw err
    }
  }

  async function fetchToken() {
    try {
      return cognito.fetchToken()
    } catch (err) {
      throw err
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes()
      return attr
    } catch (err) {
      throw err
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr)
      return res
    } catch (err) {
      throw err
    }
  }

  async function sendCode(username: string) {
    try {
      await cognito.sendCode(username)
    } catch (err) {
      throw err
    }
  }

  async function forgotPassword(username: string, code: string, password: string) {
    try {
      await cognito.forgotPassword(username, code, password)
    } catch (err) {
      throw err
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword)
    } catch (err) {
      throw err
    }
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    fetchToken,
    signInAsGuest,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    credentials,
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider