import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import {toast} from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Link, useNavigate} from "react-router-dom";
import Logo from '../../images/logo.svg'
import './style.scss';
import { AuthContext, AuthStatus } from '../../contexts/authContext';


const LoginPage = (props) => {
    const push = useNavigate()
    const authContext = useContext(AuthContext)

    useEffect(() => {
        if (authContext.authStatus == AuthStatus.SignedIn) {
            push("/")
        }
    }, [authContext.authStatus])

    const [value, setValue] = useState({
        password: '',
        remember: false,
    });

    const changeHandler = (e) => {
        setValue({...value, [e.target.name]: e.target.value});
        validator.showMessages();
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    

    const submitForm = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            setValue({
                password: '',
                remember: false
            });
            validator.hideMessages();

            try {
                await authContext.signInAsGuest(value.password)
                push("/")
                toast.dismiss()
            } catch (e) {
                toast.error(e.message)
            }
            
        } else {
            validator.showMessages();
            toast.error('Empty field is not allowed!');
        }
    };
    return (
        <Grid className="loginWrapper">
            <Grid className="loginForm">
                <img src={Logo} alt=""/>
                <p>Enter the password from your invitation to enter the website.</p>
                <form onSubmit={submitForm}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Password"
                                value={value.password}
                                variant="outlined"
                                name="password"
                                type="password"
                                label="Password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('password', value.password, 'required')}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="formFooter">
                                <Button fullWidth className="cBtnTheme" type="submit">Login</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
    )
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.currentUser,
    };
};

export default LoginPage;
  