import { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import ComingSoon from '../../components/ComingSoon';
import PageTitle from '../../components/pagetitle/PageTitle';
import Footer from '../../components/footer/Footer';



const RSVPPage = () => {
    return (
        <Fragment>
            <Navbar2 />
            <PageTitle pageTitle={'RSVP'}/> 
            <ComingSoon />
            <Footer />
        </Fragment>
    )
};

export default RSVPPage;