import { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import PageTitle from '../../components/pagetitle/PageTitle';
import NextPagePromo from '../../components/NextPagePromo';
import { PageBody } from '../../components/PageBody';



const Travel = () => {
  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={'Travel'}/> 
      <PageBody className="margin-bottom">
        <h2>Where to stay</h2>
        <p>We have blocked rooms at the <b>Richmond-North/Ashland Hampton Inn</b>, which is 25 minutes away from the venue, for our guests to book at their leisure. These rooms will be held until 3pm on May 12, 2023. To take advantage of our discounted room rate, <a target="__blank" href="https://www.hilton.com/en/attend-my-event/catherineandjoseweddingjune2023/">book your room here.</a></p>
        <br/>
        <h2>Get there By Train</h2>
        <p>The <b>Ashland Amtrak Station</b> is a 4 minute-drive from the recommended hotel and about a 30 minute drive from the venue. <a target="__blank" href="https://goo.gl/maps/wBmDPFZBQKtDE1v57">View on Google Maps.</a></p>
        <p>The <b>Richmond Staples Mill Road Amtrak Station</b> is a 35-50 minute drive from the venue and about 18-30 minutes from the recommended hotel. <a target="__blank" href="https://goo.gl/maps/DkKVcG2a3is8PaGA8">View on Google Maps.</a></p>
        <br/>
        <h2>Get there By Plane</h2>
        <p><b>Richmond International Airport</b> (RIC) is the closest airport to our venue. The venue is about a 50 minute drive away.</p>
        <p><b>Ronald Reagan Washington National Airport</b> (DCA) is the closest DC-area airport. It’s typically an 1.5-2 hour drive from the venue.</p>
        <p><b>Dulles International Airport</b> (IAD) is the furthest of 3 airports, but the largest and has the most international flights. It’s about a 2-3 hour drive from the venue.</p>
      </PageBody>
      <NextPagePromo
        title="Our Schedule"
        description='For more information about the when / where'
        linkPath='/schedule'
        linkText='View Schedule Page'
      />
      <Footer />
      <Scrollbar />
    </Fragment>
  )
};

export default Travel;