import { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import Hero3 from '../../components/hero3/hero3';
import WeddingDate from '../../components/WeddingDate/WeddingDate';
import StorySection from '../../components/StorySection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import FunFact from '../../components/FunFact/FunFact';
import SectionTitle from '../../components/SectionTitle';
import OfferBanner from '../../components/OfferBanner/OfferBanner';
import ComingSoon from '../../components/ComingSoon';
import PageTitle from '../../components/pagetitle/PageTitle';



const WeddingParty = () => {
    return (
        <Fragment>
            <Navbar2 />
            <PageTitle pageTitle={'Wedding Party'}/>
            <ComingSoon />
            <Footer />
        </Fragment>
    )
};

export default WeddingParty;