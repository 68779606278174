import React from "react";
import Header2 from "../Header2/Header2";

export default function Navbar2(props) {
  const [scroll, setScroll] = React.useState(0);

  const handleScroll = () => setScroll(document.documentElement.scrollTop);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      <div className="fixed-navbar">
        <Header2 hclass={props.hclass} Logo={props.Logo} topbarNone={props.topbarNone}/>
      </div>
      {scroll > 0 && (
        <div className="fixed-navbar active">
          <Header2 hclass={props.hclass} Logo={props.Logo} topbarNone={props.topbarNone}/>
        </div>
      )}
    </div>
  ); 
}