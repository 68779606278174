import { Button, CircularProgress } from '@material-ui/core';
import { useAddContactQuestionMutation } from '@wedding/api-schema/graphql-apollo';
import React, { ChangeEvent, FormEvent, useState } from 'react'
import { toast } from "react-toastify";
import SimpleReactValidator from 'simple-react-validator';


const FAQForm = () => {
  const [sendQuestion, { data, loading, error }] = useAddContactQuestionMutation()
  const [forms, setForms] = useState({
    name: '',
    email: '',
    question: ''
  });

  const changeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForms({ ...forms, [e.target.name]: e.target.value })
  };

  const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const { data, errors } = await sendQuestion({
        variables: {
          input: {
            name: forms.name,
            email: forms.email,
            question: forms.question,
          }
        }
      })

      if (errors && errors.length > 0 || !data?.addContactQuestion.success) {
        throw new Error(errors?.map(err => err.message).join(", ") ?? data?.addContactQuestion.info ?? "There was an error processing your request.")
      }

      toast.success("Your question has been submitted!")
      setForms({
        name: '',
        email: '',
        question: ''
      })
    } catch (e: unknown) {
      toast.error((e as Error).message)
    }
  };

  return (
    <div className='faq-form'>
      <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
        <div className="row">
          <div className="col col-lg-6 col-12">
            <div className="form-field">
              <input
                value={forms.name}
                type="text"
                name="name"
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                placeholder="Your name (required)"
              />
            </div>
          </div>
          <div className="col col-lg-6 col-12">
              <div className="form-field">
                <input
                  value={forms.email}
                  type="email"
                  name="email"
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                  placeholder="Your email (optional)"
                />
              </div>
          </div>
          <div className="col col-lg-12 col-12">
            <textarea
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              value={forms.question}
              name="question"
              placeholder="What is your question?">
            </textarea>
          </div>
        </div>
        <div className="submit-area">
          <button type="submit" disabled={!forms.name || !forms.question || loading} className="theme-btn">{loading ? <CircularProgress color='inherit' size={16} /> : "Ask us"}</button>
        </div>
      </form>
    </div>
  )
}

export default FAQForm;