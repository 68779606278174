import React from 'react'
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/story/1.jpg'
import sImg2 from '../../images/story/2.jpg'
import sImg3 from '../../images/story/3.jpg'

import shape from '../../images/story/shape.png'


const Storys = [
    {
        sImg: sImg1,
        title: 'How We Met',
        location: 'Reston, VA',
        description: 'We started working at Brightspot as our first job out of college. Every Friday after work, we hung out with our new coworkers. We always signed up for the free Capitals, Nationals, and DC United tickets together and quickly became great friends.',
    },
    {
        sImg: sImg3,
        title: 'She Said Yes',
        location: 'Arlington, VA',
        description: 'Jose proposed to Catherine on July 11, 2022 in her balcony garden. As we were getting ready to leave for a month-long trip, Jose set up an automated watering system to water Catherine’s beloved plants and capture the growth on a daily timelapse video. We then had a gardening date to finish repotting, during which he hid the ring in one of the pots Catherine was going to plant. Catherine was completely surprised by what she found in the pot, and she said yes!',
    },
    {
        sImg: sImg2,
        title: 'Our First Marathon',
        location: 'Richmond, VA',
        description: 'After our 24 weeks of training, we ran our first Marathon together. It was unusually hot, 75 degrees in November, in Richmond, VA. Catherine\'s family was there to support us and bring us water. We both were able to finish, Catherine in 4hrs 38mins and Jose in 5hrs 16mins.',
    },
]


const StorySection = (props) => {
    return (
        <section className={`wpo-story-section section-padding ${props.stClass}`} id="story">
            <div className="container-fluid">
                <div className="wpo-story-wrap">
                    {
                        Storys.map((story, st) => (
                            <div className="wpo-story-item" key={st}>
                                <div className="wpo-story-img-wrap">
                                    <div className="wpo-story-img">
                                        <img src={story.sImg} alt="" />
                                    </div>
                                    <div className="wpo-img-shape">
                                        <img src={shape} alt="" />
                                    </div>
                                </div>
                                <div className="wpo-story-content">
                                    <div className="wpo-story-content-inner">
                                        <h2>{story.title}</h2>
                                        <span>{story.location}</span>
                                        <p>{story.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))

                    }
                </div>
            </div>
        </section>
    )
}

export default StorySection;