import { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import Hero3 from '../../components/hero3/hero3';
import WeddingDate from '../../components/WeddingDate/WeddingDate';
import StorySection from '../../components/StorySection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import FunFact from '../../components/FunFact/FunFact';
import SectionTitle from '../../components/SectionTitle';
import OfferBanner from '../../components/OfferBanner/OfferBanner';
import PageTitle from '../../components/pagetitle/PageTitle';
import NextPagePromo from '../../components/NextPagePromo';
import { PageBody } from '../../components/PageBody';



const Schedule = () => {
  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={'Schedule'}/> 
      <PageBody className="margin-bottom">
        <h2>Friday, June 2</h2>
        <b>Welcome Party</b><br/>
        <b>6:46-8:00 PM, The Pine Barn at The Barns at Mattaponi Springs</b>
        <p>Please feel free to drop by The Pine Barn for dessert and refreshments anytime between 6:46 to 8:00pm.<br/>Attire: Casual</p>
        <br/>
        <h2>Saturday, June 3</h2>
        <b>4:30pm-10pm, The Barns at Mattaponi Springs</b>
        <p>Reception to follow immediately after the ceremony.</p>
      </PageBody>
      <NextPagePromo
        title="Frequently Asked Questions"
        description='Looking for answers to common questions about our wedding? We’ve got you covered.'
        linkPath='/faq'
        linkText='View FAQ Page'
      />
      <Footer />
      <Scrollbar />
    </Fragment>
  )
};

export default Schedule;