import { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import PageTitle from '../../components/pagetitle/PageTitle';
import { FAQItem } from '../../components/FAQItem';
import { PageBody } from '../../components/PageBody';
import NextPagePromo from '../../components/NextPagePromo';
import FAQForm from '../../components/FAQForm/FAQForm';



const FAQ = () => {
  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={'FAQ'} />
      <PageBody className="margin-bottom">
        <div className='wpo-benefits-item'>
          <FAQItem question='How can I contact the Catherine & Jose?'>
            You can email us at <a href='mailto:catherine.jose.wedding@gmail.com'>catherine.jose.wedding@gmail.com</a>
            <br />
            The best place for mail before we return from our honeymoon is Catherine’s parents’ house:
            <br />
            13208 Herringbone Ct. Henrico, VA 23233
          </FAQItem>
          <FAQItem question='What are my options for dinner?'>
            Chicken: Frenched chicken breast, demi cream sauce; served with savory herbed potato galette, butter braised carrots, and haricot verts
            <br/><br/>
            Steak: Sirloin Steak au Poivre, red demi-glace; served with savory herbed potato galette, butter braised carrots, and haricot verts
            <br/><br/>
            Vegan: Moroccan winter squash + chickpea tagine; includes couscous, winter squash, dried apricots, almonds, and fine herbs
          </FAQItem>
          <FAQItem question='Where is the wedding?'>
            All wedding events, including the rehearsal dinner, ceremony, and reception, are located at The Barns at Mattaponi Springs. The address is 23134 Penola Rd, Ruther Glen, VA 22546. <a href="https://goo.gl/maps/BiNoysw7hCUMk9Cz8" target="__blank">View on Google Maps.</a>
            <br /><br />
            More information about the venue can be found at <a href='https://mattaponisprings.com/weddings/' target="__blank">their website.</a>
          </FAQItem>
          <FAQItem question='Do you have a gift registry set up?'>
            Yes, you can view our <a href="https://registry.theknot.com/-june-2023-va/58317888" target="__blank">registry following this link</a>.
          </FAQItem>
          <FAQItem question='Is parking available at the venue?'>
            Yes, there is a large lot with ample parking at the venue.
          </FAQItem>
          <FAQItem question='What should I wear?'>
            This is your chance to dress up! Cocktail, Semi-formal, or formal attire is recommended. Here's an article with some examples of cocktail attire <a href="https://www.theknot.com/content/cocktail-wedding-attire" target="__blank">https://www.theknot.com/content/cocktail-wedding-attire</a>
            <br/><br/>
            More than anything we will love to see you there, but this is a chance to show off those fancy suits, pantsuits, and dresses.
          </FAQItem>
          <FAQItem question='Is the wedding indoor or outdoors?'>
            Weather permitting, the ceremony will be outside and the reception will be inside.
          </FAQItem>
          <FAQItem question='What weather can I expect?'>
            In the Richmond area, early June weather is typically in the 60s-80s and is likely to cool off some overnight.
          </FAQItem>
          <FAQItem question='How can I get back to the hotel from the wedding?'>
            Ubers are not commonly available in this area, so we recommend pre-arranging a ride or carpooling. You can leave your car at the venue overnight and pick it up in the morning. Please reach out to us if you’d like help coordinating a ride!
          </FAQItem>
          <FAQItem question='Don&rsquo;t see your question here?'>
            We're always happy to answer any questions you have! To submit questions please fill out the form below and we might add your question and our answer to this page.
            <br/><br/>
            You can also email us at <a href='mailto:catherine.jose.wedding@gmail.com' target="__blank">catherine.jose.wedding@gmail.com</a>.
          </FAQItem>
          <FAQForm />
        </div>
      </PageBody>
      <NextPagePromo
        title="Our Photos"
        description='Check out our memory wall to view some of our favorite photos together.'
        // title="View our gallery"
        // description='Take a look at our collection of sweet memories throughout the years'
        linkPath='/photos'
        linkText='View Memory Wall'
      />
      <Footer />
      <Scrollbar />
    </Fragment>
  )
};

export default FAQ;