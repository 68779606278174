import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from "react";

interface FaqItemProps {
  question: string;
  children: React.ReactNode;
}

export const FAQItem = (props: FaqItemProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} className="faq-item">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
      >
        <span>{props.question}</span>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {props.children}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}