interface PageBodyProps {
  children: React.ReactNode;
  className?: string
}

export const PageBody = (props: PageBodyProps) => {
  return (
    <div className={`container page-body ${props.className ?? ""}`}>
      <div className="row">
        <div className="col-lg-10 col-xl-8 col-12">
          {props.children}
        </div>
      </div>
    </div>
  )
}